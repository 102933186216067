import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Swiper from "swiper"
import $ from "jquery"
import ArrowBlack from "../img/arrow-black.svg"
import { addUtmTagsInUrls, setCookieFromUrl } from "../widgets/cookie"

export default function BlogPosts(props: any) {
  const data =
    props?.data?.allContentfulBlogPost?.edges?.map((e) => e.node) ?? []

  React.useEffect(() => {
    new Swiper(".caseStudiesSlider", {
      slidesPerView: 1.8,
      spaceBetween: 20,
      freeMode: true,
      breakpoints: {
        400: {
          slidesPerView: 2.5,
        },
        600: {
          slidesPerView: 3.5,
        },
        800: {
          slidesPerView: 3.5,
        },
        990: {
          slidesPerView: 1.8,
        },
        1200: {
          slidesPerView: 2.5,
        },
      },
    })

    const filterClass = "active"
    const arr = {
      arr1: [],
      arr2: [],
    }
    $(document).on("click", ".workFilter", function () {
      const parent = $(this).parents(".case-studies-filter")
      const dataFilter = $(this).attr("data-filter")
      const showAll = parent.find(".showAll")
      const workFilter = parent.find(".workFilter")
      const workItem = parent.find(".workItem")
      const arrName = parent.attr("data-array-name")
      if ($(this).hasClass("showAll")) {
        workFilter.removeClass(filterClass)
        showAll.addClass(filterClass)
        workItem.addClass(filterClass)
        arr[arrName] = []
      } else {
        showAll.removeClass(filterClass)
        if ($(this).hasClass(filterClass)) {
          $(this).removeClass(filterClass)
          removeActiveFilter(arr[arrName], dataFilter)
        } else {
          $(this).addClass(filterClass)
          arr[arrName].push(dataFilter)
        }

        if (arr[arrName].length > 0) {
          workItem.removeClass(filterClass)
          arr[arrName].forEach((element) => {
            $("." + element).addClass(filterClass)
            // $('.' + element).wrap('<hidden>');
          })
        } else {
          workFilter.removeClass(filterClass)
          showAll.addClass(filterClass)
          workItem.addClass(filterClass)
          arr[arrName] = []
          // $('hidden').unwrap();
        }
      }
    })

    function removeActiveFilter(arr, item) {
      const index = arr.indexOf(item)
      if (index > -1) {
        arr.splice(index, 1)
      }
    }
  })

  if (!props.data) return <div>empty</div>
  const p = {
    ...props,
    title: "Blogs By OPA",
    description:
      "Learn More About influencer marketing & influencer management Via Our latest Blogs.",
  }
  React.useEffect(() => {
    setCookieFromUrl()
  }, [1])
  React.useEffect(() => {
    addUtmTagsInUrls({ links: document.querySelectorAll("a") })
  }, [])
  return (
    <Layout {...p}>
      <div className="mainContainer" style={{ backgroundColor: "#fff" }}>
        <section className="case-studies-banner">
          <div className="container">
            <div className="case-studies-banner-left">
              <h1>
                <span className="text-highlight-long">Blog</span>
              </h1>
              <p>a glimpse into whats been happening</p>
            </div>
          </div>
          {/* <div className="case-studies-banner-right">
            <div className="swiper caseStudiesSlider">
              <div className="swiper-wrapper" style={{ width: 1000 }}>
                {data?.map((e) => (
                  <div className="swiper-slide">
                    <div
                      // className="csImg"
                      style={{ border: "1px solid grey" }}
                    >
                      <img src={e.previewContent.previewImage} alt="img" />
                    </div>
                    <h4>
                      <Link to={`/blog/${e.slug}/`}>
                        {e.title}
                      </Link>
                    </h4>
                    <p>{e.previewContent.previewDescription}</p>
                    <Link to={`/blog/${e.slug}/`}>
                      Read more
                      <ArrowBlack />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
          <div className="case-studies-banner-blog">
            <div className="container caseStudiesSlider">
              <div className="row">
                {data?.map((e) => (
                  <div className="col-lg-4 col-md-6 mb-4">
                    <div className="content-block">
                      <div
                        // className="csImg"
                        style={{ border: "1px solid grey" }}
                      >
                        <img src={e?.previewContent?.previewImage} alt="img" />
                      </div>
                      <h4>
                        <Link to={`/blog/${e.slug}/`}>{e.title}</Link>
                      </h4>
                      <p>{e?.previewContent?.previewDescription}</p>
                      <Link to={`/blog/${e.slug}/`}>
                        Read more
                        <ArrowBlack />
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allContentfulBlogPost {
      edges {
        node {
          title
          slug
          previewContent {
            previewImage
            previewDescription
          }
        }
      }
    }
  }
`
